<div class="page">
  <div class="admin">
    <app-e-logo />
    <div class="error">
      <img src="assets/images/not-found-error.png" />
      <p class="merck-font-title">{{ textConstants.ERROR_404 }}</p>
      <p class="error-message">{{ textConstants.ERROR_MESSAGE }}</p>
      <app-e-button [textContent]="textConstants.BACK" routerLink="/" />
    </div>
  </div>
</div>
