import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as TextConstants from '../constants/text.constants';
import { environment } from '../../environments/environment';

export const adminsGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const textConstants = TextConstants;

  if (!authService.isLoggedIn) {
    window.location.href = `${environment.baseUrl}/api/auth/authorize?returnUrl=${state.url}`;
    return false;
  }

  if (!authService.isInRole([textConstants.TEAM_LEAD, textConstants.CONTENT_MANAGER, textConstants.SUPER_ADMIN, textConstants.ADMIN])) {
    return router.createUrlTree(['/get-access']);
  }

  return authService.isLoggedIn;
};
