import { Component } from '@angular/core';
import { Location } from '@angular/common';
import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
  textConstants = TextConstants;

  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
